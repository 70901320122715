import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-c2669b50"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "container"
};
var _hoisted_2 = {
  "class": "header"
};
var _hoisted_3 = {
  "class": "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _a;
  var _component_el_checkbox = _resolveComponent("el-checkbox");
  return _openBlock(), _createElementBlock("div", {
    "class": "message-item",
    onClick: $setup.handleClick
  }, [$props.checkOrRadio ? (_openBlock(), _createBlock(_component_el_checkbox, {
    key: 0,
    style: {
      "margin-right": "1rem"
    },
    name: "type",
    modelValue: $setup.checked,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $setup.checked = $event;
    }),
    onClick: $setup.handleFalse
  }, null, 8 /* PROPS */, ["modelValue"])) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    "class": _normalizeClass(["message-radio", ((_a = $props.item) === null || _a === void 0 ? void 0 : _a.newFlag) ? 'message-new' : ''])
  }, null, 2 /* CLASS */)), _createElementVNode("view", _hoisted_1, [_createElementVNode("view", _hoisted_2, [_renderSlot(_ctx.$slots, "header", {}, undefined, true)]), _createElementVNode("view", _hoisted_3, [_renderSlot(_ctx.$slots, "content", {}, undefined, true)])])]);
}