import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-26798954"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "assignments-history"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = {
  "class": "date"
};
var _hoisted_4 = {
  style: {
    "flex": "1"
  }
};
var _hoisted_5 = {
  "class": "name"
};
var _hoisted_6 = {
  style: {
    "flex": "1"
  }
};
var _hoisted_7 = {
  "class": "start-date"
};
var _hoisted_8 = {
  key: 0,
  style: {
    "width": "100%"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode($setup["DeepRefreshView"], {
    ref: "scrollViewRef"
  }, {
    "default": _withCtx(function (_a) {
      var data = _a.data;
      return [_createElementVNode("div", {
        onClick: function onClick($event) {
          return $setup.open(data);
        },
        "class": "item"
      }, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString($setup.parseTime1(data.createTime)), 1 /* TEXT */), _createElementVNode("div", null, _toDisplayString($setup.parseTime2(data.createTime)), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, _toDisplayString(data.assignmentsName), 1 /* TEXT */), _createVNode($setup["DeepSvgIcon"], {
        name: "arrow-right"
      })]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", null, _toDisplayString($setup.parseTime3(data.assignmentsBegin)), 1 /* TEXT */)]), $setup.JlinkType.assignments.isAssignmentsAdmin(data) ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode($setup["DeepButton"], {
        size: "small",
        type: "danger",
        style: {
          "margin-top": "1rem",
          "width": "6rem",
          "float": "right"
        },
        onClick: _withModifiers(function ($event) {
          return $setup.handleDelete(data.assignmentsId);
        }, ["stop"])
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode("删除任务")];
        }),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])])) : _createCommentVNode("v-if", true)], 8 /* PROPS */, _hoisted_2)];
    }),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */)]);
}