import DeepDrawerInitialize from '@/components/DeepDrawerInitialize'
import { Ref } from 'vue'

export default class DrawerFilesShowHelper {
  drawer:DeepDrawerInitialize<FileShowValueData>

  constructor (adjustEl?:Ref<HTMLElement|undefined>) {
    this.drawer = new DeepDrawerInitialize<FileShowValueData>({
      direction: 'ltr',
      adjustParent: adjustEl,
      size: '100%',
      component: () => import('@/app/views/drawer/DrawerFilesShow.vue'),
    })
  }

  open (data:FileShowValueData) {
    this.drawer.open(data)
  }

  showDocuments (selectId: string, list: DocumentWrapperVo[]) {
    const myList: FileShowValueDataListItem[] = []
    for (const i of list.filterIf('extType', ['image', 'video'])) {
      myList.push({
        id: i.documentId,
        fileExt: i.documentExt,
        extType: i.extType,
        url: i.documentUrl,
        name: i.documentTitle,
        fileSize: i.fileSize,
        createTime: i.createTimeFormat
      })
    }
    this.drawer.open({ selected: selectId, list: myList })
  }

  async showUploadFile (selectId: string, list: UploadFileWrapperVo[]) {
    const myList: FileShowValueDataListItem[] = []
    for (const i of list.filterIf('extType', ['image', 'video'])) {
      myList.push({
        id: i.fileId,
        fileExt: i.fileExt,
        extType: i.extType,
        url: i.fileObjectKey,
        name: i.fileName,
        fileSize: i.fileSize,
        createTime: i.createTimeFormat,
        shootPayload: i.filePayloadEnumDesc,
        shootDevice: i.fileDeviceEnumDesc,
        taskName: i.taskName,
        metadata: i.metadataFormat
      })
    }
    this.drawer.open({ selected: selectId, list: myList })
  }

  async showUploadFileWithDetectedFile (file: UploadFileWrapperVo) {
    const myList: FileShowValueDataListItem[] = [{
      id: file.fileId,
      extType: file.extType,
      fileExt: file.fileExt,
      url: file.fileObjectKey,
      name: file.fileName,
      fileSize: file.fileSize,
      createTime: file.createTimeFormat,
      shootPayload: file.filePayloadEnumDesc,
      shootDevice: file.fileDeviceEnumDesc,
      taskName: file.taskName,
      metadata: file.metadataFormat
    }]
    if (file.detectedFilesWrapper) {
      for (const i of file.detectedFilesWrapper) {
        myList.push({
          fileExt: i.fileExt,
          id: i.detectedFileId,
          extType: i.extType,
          url: i.fileObjectKey,
          name: i.fileObjectKey.split('/').slice(-1)[0],
          fileSize: i.fileSize,
          createTime: i.createTimeFormat,
          metadata: i.metadataFormat,
        })
      }
    }
    console.log(file, 'file', myList)
    this.drawer.open({ selected: file.fileId, list: myList })
  }

  close () {
    this.drawer.close()
  }
}
