import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  "class": "title",
  style: {
    "font-size": "1.2rem"
  }
};
var _hoisted_2 = {
  style: {
    "font-size": ".6rem",
    "color": "#808080"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock($setup["MessageBase"], {
    item: $props.item,
    checkOrRadio: $props.checkOrRadio,
    onClick: $setup.openMessage
  }, {
    header: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, _toDisplayString($setup.title), 1 /* TEXT */), _createElementVNode("div", _hoisted_2, _toDisplayString($setup.createTime), 1 /* TEXT */)];
    }),
    content: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($setup.content), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["item", "checkOrRadio"]);
}