import "core-js/modules/es.function.name.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-833af174"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "task-type-select"
};
var _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.selectItems, function (item) {
    return _createElementVNode("div", {
      onClick: function onClick($event) {
        return $setup.select(item);
      },
      key: item.key
    }, _toDisplayString(item.name), 9 /* TEXT, PROPS */, _hoisted_2);
  }), 64 /* STABLE_FRAGMENT */))]);
}