import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  style: {
    "width": "100%",
    "padding-top": "16px",
    "display": "flex",
    "flex-direction": "row",
    "justify-content": "flex-end"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_input = _resolveComponent("el-input");
  var _component_el_form_item = _resolveComponent("el-form-item");
  var _component_DeepDatePicker = _resolveComponent("DeepDatePicker");
  var _component_DeepButton = _resolveComponent("DeepButton");
  var _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createBlock(_component_el_form, {
    ref: "droneInsuranceRef"
  }, {
    "default": _withCtx(function () {
      return [_ctx.insuranceData.insuranceId ? (_openBlock(), _createBlock(_component_el_form_item, {
        key: 0,
        label: "保单id"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_input, {
            modelValue: _ctx.insuranceData.insuranceId,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return _ctx.insuranceData.insuranceId = $event;
            }),
            disabled: ""
          }, null, 8 /* PROPS */, ["modelValue"])];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _createVNode(_component_el_form_item, {
        label: "保单编号",
        prop: "insuranceNo"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_input, {
            modelValue: _ctx.insuranceData.insuranceNo,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
              return _ctx.insuranceData.insuranceNo = $event;
            }),
            maxlength: "16",
            placeholder: "请输入保单编号(1-32)",
            "show-word-limit": "",
            disabled: !_ctx.editable
          }, null, 8 /* PROPS */, ["modelValue", "disabled"])];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_form_item, {
        label: "保单名称",
        prop: "insuranceName"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_input, {
            modelValue: _ctx.insuranceData.insuranceName,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
              return _ctx.insuranceData.insuranceName = $event;
            }),
            maxlength: "16",
            placeholder: "请输入保单名称(1-16)",
            "show-word-limit": "",
            disabled: !_ctx.editable
          }, null, 8 /* PROPS */, ["modelValue", "disabled"])];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_form_item, {
        label: "保单描述",
        prop: "insuranceDescribe"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_input, {
            type: "textarea",
            modelValue: _ctx.insuranceData.insuranceDescribe,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
              return _ctx.insuranceData.insuranceDescribe = $event;
            }),
            maxlength: "256",
            placeholder: "请输入保单描述(1-256)",
            "show-word-limit": "",
            disabled: !_ctx.editable
          }, null, 8 /* PROPS */, ["modelValue", "disabled"])];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_form_item, {
        label: "保单生效日期",
        prop: "insuranceEffectiveDate"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_DeepDatePicker, {
            style: {
              "width": "100%"
            },
            modelValue: _ctx.insuranceData.insuranceEffectiveDate,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
              return _ctx.insuranceData.insuranceEffectiveDate = $event;
            }),
            disabled: !_ctx.editable
          }, null, 8 /* PROPS */, ["modelValue", "disabled"])];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_form_item, {
        label: "保单失效日期",
        prop: "insuranceExpireData"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_DeepDatePicker, {
            style: {
              "width": "100%"
            },
            modelValue: _ctx.insuranceData.insuranceExpireData,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
              return _ctx.insuranceData.insuranceExpireData = $event;
            }),
            disabled: !_ctx.editable
          }, null, 8 /* PROPS */, ["modelValue", "disabled"])];
        }),
        _: 1 /* STABLE */
      }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_DeepButton, {
        type: "primary",
        size: "small",
        onClick: _ctx.droneInsuranceSubmit
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.editable ? _ctx.droneInsuranceActionName : '编辑'), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"])])];
    }),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */);
}