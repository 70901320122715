import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.error.to-string.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.parse-int.js";
import { __assign } from "tslib";
import { watch, ref, defineComponent } from 'vue';
import { DeepElFormInitialize, RuleMaker } from '@/components/DeepElFormInitialize';
import DeepButton from '@/components/DeepButton.vue';
import DeepDatePicker from '@/components/DeepDatePicker.vue';
export default defineComponent({
  components: {
    DeepDatePicker: DeepDatePicker,
    DeepButton: DeepButton
  },
  props: {
    insurance: {
      type: Object
    }
  },
  emits: ['submit'],
  setup: function setup(props, ctx) {
    var editable = ref(false);
    var insuranceData = ref({});
    var droneInsuranceActionName = ref('');
    watch(function () {
      return props.insurance;
    }, function (v) {
      if (v) {
        insuranceData.value = __assign(__assign({}, v), {
          insuranceExpireData: v.insuranceExpireData + '',
          insuranceEffectiveDate: v.insuranceEffectiveDate + ''
        });
        droneInsuranceActionName.value = '编辑保险资料';
        editable.value = false;
      } else {
        insuranceData.value = {
          insuranceId: '',
          insuranceNo: '',
          insuranceName: '',
          insuranceDescribe: '',
          insuranceEffectiveDate: new Date().getTime() + '',
          insuranceExpireData: new Date().getTime() + ''
        };
        droneInsuranceActionName.value = '上传保险资料';
        editable.value = true;
      }
    }, {
      immediate: true,
      deep: true
    });
    var formInit1 = new DeepElFormInitialize({
      model: insuranceData,
      labelWidth: 120,
      validateOnRuleChange: false
    });
    formInit1.modifyRules(function (rules, model) {
      return {
        insuranceName: new RuleMaker().setMax(16).setMin(0).setMessage('请输入保单名称(1-16)'),
        insuranceNo: new RuleMaker().setMax(16).setMin(0).setMessage('请输入保单编号(1-32)'),
        insuranceDescribe: new RuleMaker().setMax(256).setMin(0).setMessage('请输入保单描述(1-256)'),
        insuranceEffectiveDate: new RuleMaker().setValidate(function (v) {
          formInit1.clearValidate('insuranceExpireData');
          if (parseInt(v) > parseInt(model.insuranceExpireData)) {
            return new Error('保单的生效时间不能晚于保单过期时间');
          }
        }, function () {
          formInit1.validateField('insuranceExpireData', function () {});
        }),
        insuranceExpireData: new RuleMaker().setValidate(function (v) {
          formInit1.clearValidate('insuranceEffectiveDate');
          if (parseInt(v) < parseInt(model.insuranceEffectiveDate)) {
            return new Error('保单的过期时间不能早于保单生效时间');
          } else if (parseInt(v) < new Date().getTime()) {
            return new Error('保单的过期时间不能早于当前时间');
          }
        }, function () {
          formInit1.validateField('insuranceEffectiveDate', function () {});
        })
      };
    });
    function droneInsuranceSubmit() {
      if (!editable.value) {
        editable.value = true;
        return;
      }
      formInit1.validate(function (model) {
        ctx.emit('submit', model);
      });
    }
    return {
      insuranceData: insuranceData,
      droneInsuranceActionName: droneInsuranceActionName,
      droneInsuranceSubmit: droneInsuranceSubmit,
      editable: editable,
      droneInsuranceRef: formInit1.targetRef
    };
  }
});