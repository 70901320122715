import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3294a7ea"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "notify-bar"
};
var _hoisted_2 = {
  "class": "bar-title"
};
var _hoisted_3 = {
  "class": "icons"
};
var _hoisted_4 = {
  "class": "title-text"
};
var _hoisted_5 = {
  "class": "icons icon-bottom"
};
var _hoisted_6 = {
  style: {
    "flex": "1",
    "width": "100%",
    "padding": "1rem",
    "overflow": "hidden"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_checkbox = _resolveComponent("el-checkbox");
  var _component_el_tooltip = _resolveComponent("el-tooltip");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [$setup.checkOrRadio ? (_openBlock(), _createBlock(_component_el_checkbox, {
    key: 0,
    name: "type",
    style: {
      "margin-right": "1rem"
    },
    onChange: $setup.handleCheck
  })) : _createCommentVNode("v-if", true), $setup.messageCount > 0 ? (_openBlock(), _createBlock(_component_el_tooltip, {
    key: 1,
    content: "已读"
  }, {
    "default": _withCtx(function () {
      return [_createVNode($setup["DeepSvgIcon"], {
        "class": "more",
        name: "check-circle",
        onClick: $setup.handleRead
      })];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true)]), _createElementVNode("span", _hoisted_4, "消息(" + _toDisplayString($setup.messageCount) + ")", 1 /* TEXT */), _createElementVNode("div", _hoisted_5, [$setup.messageCount > 0 ? (_openBlock(), _createBlock(_component_el_tooltip, {
    key: 0,
    content: "删除"
  }, {
    "default": _withCtx(function () {
      return [_createVNode($setup["DeepSvgIcon"], {
        "class": "more",
        name: "svg-trash",
        onClick: $setup.handleDelete
      })];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true), $setup.checkOrRadio ? (_openBlock(), _createBlock(_component_el_tooltip, {
    key: 1,
    content: "取消"
  }, {
    "default": _withCtx(function () {
      return [_createVNode($setup["DeepSvgIcon"], {
        "class": "more",
        name: "close",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return $setup.checkOrRadio = false;
        }),
        style: {
          "margin-left": "1rem"
        }
      })];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true)])]), _createElementVNode("div", _hoisted_6, [_createVNode($setup["DeepRefreshView"], {
    ref: "scrollViewRef"
  }, {
    "default": _withCtx(function (_a) {
      var data = _a.data;
      return [(_openBlock(), _createBlock(_resolveDynamicComponent($setup.covertType(data.messageType)), {
        item: data,
        checkOrRadio: $setup.checkOrRadio,
        onTriggerClose: $setup.handleCloseBar
      }, null, 40 /* PROPS, NEED_HYDRATION */, ["item", "checkOrRadio"]))];
    }),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */)])]);
}